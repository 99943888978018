import React, { useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-scroll";
import Sidebar from "./Sidebar";

import Logo from "../Logo/Logo";
// import { Link } from "react-scroll";
const Wrapper = styled.div`
  width: 100%;
  background: #000;

  ${"" /* for logo */}
  @keyframes scaleUpDown {
    0%,
    100% {
      transform: scaleY(1) scaleX(1);
    }
    50%,
    90% {
      transform: scaleY(1.1);
    }
    75% {
      transform: scaleY(0.95);
    }
    80% {
      transform: scaleX(0.95);
    }
  }

  @keyframes shake {
    0%,
    100% {
      transform: skewX(0) scale(1);
    }
    50% {
      transform: skewX(5deg) scale(0.9);
    }
  }

  @keyframes particleUp {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      top: -100%;
      transform: scale(0.5);
    }
  }

  @keyframes glow {
    0%,
    100% {
      background-color: #ef5a00;
    }
    50% {
      background-color: #ff7800;
    }
  }

  .navbars {
    padding: 0 50px 0 30px;
  }

  a {
    text-decoration: none;

    font-size: 16px;
    font-family: "Rubik";
    font-style: normal;

    color: #fff;
  }
  .menu-container {
    display: flex;
    justify-content: space-between;
  }
  .menu-container .nav-items {
    color: #fff;
    text-decoration: none;
    font-weight: 400;
    padding: 0px 20px;
  }

  .hamburger {
    display: none;
    cursor: pointer;
  }
  .nav-dashboard {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
  .dashboard {
    padding: 14px 32px;
    background: #da3a02;

    border-radius: 32px;
  }

  .torch {
    font-size: 24px;
  }
  .lab {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.8);
    padding: 0 6px;
  }

  @media only screen and (max-width: 991px) {
    .navbars {
      padding: 0 50px 0 30px;
    }

    .menu-container .nav-items {
      font-size: 16px;
      padding: 0px 16px;
    }
    .menu-container {
      display: none;
    }
    .hamburger {
      display: block;
    }
  }
  @media only screen and (max-width: 953px) {
    .menu-container .nav-items {
      font-size: 15px;
      padding: 10px 8px;
    }
  }
  @media only screen and (max-width: 767px) {
    .navbars {
      padding: 0 25px 0 5px;
    }
  }
  @media only screen and (max-width: 520px) {
    .logo-container {
      margin: 20px 20px;
      margin-right: 20px;
    }
    .fire {
      width: 30px;
      height: 30px;
    }
    .torch {
      font-size: 16px;
    }
    .lab {
      font-size: 16px;
    }
  }
`;

const Navbar = () => {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => {
    setSidebar((prev) => !prev);
  };
  const menuItem = [
    { text: "Products", url: "explore" },
    {
      text: "Features",
      url: "explore",
    },
    {
      text: "About",
      url: "about",
    },
    {
      text: "Contact",
      url: "contact",
    },
  ];
  return (
    <Wrapper>
      <Container fluid className=" navbars" xs={11}>
        <Row>
          <Col xs={6} md={2}>
            <NavLink
              to="/"
              smooth={true}
              duration="500"
              className="d-flex align-items-center"
            >
              <Logo />
              <span className="torch">Torch</span>
              <span className="lab">Labs</span>{" "}
            </NavLink>
          </Col>

          <Col
            xs={6}
            md={10}
            className="d-flex justify-content-end align-items-center"
          >
            <div className="menu-container">
              {menuItem.map((el, i) => (
                <Link to={el.url} className="nav-items">
                  <NavLink activeClassName="active" to={el.url} key={i}>
                    {el.text}
                  </NavLink>
                </Link>
              ))}
              <div className=" nav-items nav-dashboard">
                <a
                  href="https://dashboard.torchlabs.xyz/dashboard"
                  target="_blank"
                  className="dashboard"
                  rel="noreferrer"
                >
                  Dashboard
                </a>
              </div>
            </div>
            <GiHamburgerMenu
              className="hamburger"
              size="30"
              color="#fff"
              onClick={showSidebar}
            />
          </Col>
        </Row>
        {sidebar && <Sidebar menuItem={menuItem} showSidebar={showSidebar} />}
      </Container>
    </Wrapper>
  );
};

export default Navbar;

import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import Buttons from "../Buttons/Button";

const Wrapper = styled.div`
  overflow-x: hidden;
  background: #000;
  padding: 60px 40px;

  .a-container {
    background: #da3a02;
    padding: 40px;
    color: #fff;
    border-radius: 12px;
    font-family: "DM Sans";
  }
  .a-title {
    font-style: normal;
    font-weight: bold;
    font-size: 29.7143px;
    line-height: 150%;
    color: #ffffff;
  }

  /* identical to box height, or 45px */

  /* Neutral/White */

  .a-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 14.8571px;
    line-height: 26px;
    /* or 175% */

    letter-spacing: 0.01em;

    /* Neutral/White */

    color: #ffffff;
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
  }
  @media only screen and (max-width: 767px) {
    .a-container {
      background: #da3a02;
      padding: 40px 15px;
      color: #fff;
      border-radius: 12px;
      font-family: " DM Sans";
    }
    .a-title {
      font-size: 24px;
    }

    .a-subtitle {
      font-size: 13px;
      line-height: 26px;
    }
  }
  @media only screen and (max-width: 575px) {
    .question-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .button-container {
      display: flex;
      justify-content: center;
      padding-top: 25px;
    }
  }
  @media only screen and (max-width: 450px) {
    padding: 60px 20px;
  }
`;
const AnyQuestion = () => {
  return (
    <Wrapper id="contact">
      <Row className="a-container">
        <Col sm={6} className="question-container">
          <h3 className="a-title">Have any questions?</h3>
          <span className="a-subtitle">
            Join our Discord server to find out more!
          </span>
        </Col>
        <Col sm={6} className="button-container">
          <a
            href="https://discord.com/invite/myfMsdQhER"
            target="_blank"
            rel="noreferrer"
          >
            <Buttons background="#fff" color="#da3a02">
              Learn More
            </Buttons>
          </a>
        </Col>
      </Row>
    </Wrapper>
  );
};
export default AnyQuestion;

import React, { useContext, useState } from "react";

const ProductsContext = React.createContext();

export const ContextProvider = ({ children }) => {
  const [proxy, setProxy] = useState(false);

  // end of tab details
  return (
    <ProductsContext.Provider value={{ proxy, setProxy }}>
      {children}
    </ProductsContext.Provider>
  );
};
// make sure use
export const useMainContext = () => {
  return useContext(ProductsContext);
};

import React from "react";
import styled from "styled-components";

import { BiChevronRight } from "react-icons/bi";

const Wrapper = styled.div`
  padding: 14px 0;
  .button {
    outline: 0;
    border: 0;
    padding: 10px 18px;
    background: var(--main-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    border-radius: 32px;
    width: 200px;
    color: #fff;
  }
  .right-icon {
    background: #fff;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 50%;
  }
  @media only screen and (max-width: 1199px) {
    .button {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 767px) {
    .button {
      font-size: 15px;
    }
  }
`;
const Buttons = ({ background, color, children }) => {
  return (
    <Wrapper>
      <button
        className="button d-flex justify-content-between align-items-center"
        style={{
          background: background ? "#fff" : "",
          color: background && "#DA3A02",
        }}
      >
        {children}
        <span
          className="right-icon"
          style={{ background: background ? "#DA3A02" : "" }}
        >
          <BiChevronRight color={background ? "#fff" : "var(--main-color)"} />
        </span>
      </button>
    </Wrapper>
  );
};
export default Buttons;

import React from "react";
import styled from "styled-components";
import { Col } from "react-bootstrap";
import shockynotify from "../../images/shock notify.png";
import rogue from "../../images/rogue.png";
import infernolab from "../../images/infernolab.png";
import swiftcookies from "../../images/swift cook.png";
import crownaco from "../../images/crown aco.png";
import okami from "../../images/okami.png";
import nextproxy from "../../images/nest proxy.png";
import paradise from "../../images/paradise.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Wrapper = styled.div`
  background: #000;
  overflow: hidden;
  .testimonial-container {
    height: 400px;
  }
  .section-name {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 40px;
    display: block;
    /* or 267% */

    text-align: center;
    letter-spacing: 2.1px;
    text-transform: uppercase;

    color: #da3a02;
  }
  .t-heading {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 55px;
    padding: 0 15px;
    padding-bottom: 30px;

    /* or 137% */

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: -1.5px;

    color: #ffffff;
  }
  .testimonial {
    font-family: "DM Sans";
  }
  .testimonial2 {
    padding: 25px 25px;
    padding-top: 35px;
    background: #1c1c1c;

    border-radius: 12px;
    border: 1px solid #da3a02;
  }
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 33px;

    /* identical to box height, or 206% */

    display: flex;
    align-items: center;
    color: #ffffff;
  }
  .text {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 38px;
    height: 180px;
    display: flex;
    align-items: center;
    /* or 211% */

    color: #ffffff;
  }
  .info {
    display: flex;

    align-items: center;
    height: 50px;
  }

  .nameAndcontact {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 10px;
  }
  .profile {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
  }
  .name {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    /* or 250% */

    letter-spacing: -0.4px;

    color: #ffffff;
  }
  .contact {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;

    /* or 267% */

    letter-spacing: -0.375px;
    color: #da3a02;
  }
  @media only screen and (max-width: 1199px) {
    .title {
      font-size: 14px;
      line-height: 30px;
    }
    .text {
      font-size: 15px;
      line-height: 32px;
    }

    .name {
      font-size: 14px;

      /* or 250% */
    }
    .contact {
      font-size: 13px;
    }
  }
  @media only screen and (max-width: 991px) {
    .text {
      height: 120px;
    }
  }
  @media only screen and (max-width: 767px) {
    .t-heading {
      font-size: 30px;
      line-height: 40px;
    }
  }
  @media only screen and (max-width: 600px) {
    .profile {
      width: 40px;
      height: 40px;
    }
    .title {
      font-size: 16px;
      line-height: 30px;
    }
    .text {
      font-size: 18px;
      line-height: 32px;
    }

    .name {
      font-size: 15px;

      /* or 250% */
    }
    .contact {
      font-size: 15px;
    }
  }

  @media only screen and (max-width: 400px) {
    .title {
      font-size: 14px;
      line-height: 30px;
    }
    .text {
      font-size: 15px;
      line-height: 32px;
    }

    .name {
      font-size: 14px;

      /* or 250% */
    }
    .contact {
      font-size: 13px;
    }
  }
`;
const Testimonnial = () => {
  const testimonialArray = [
    {
      img: shockynotify,
      title: "Modern look & trending design",
      text: "Amazing service, super easy and fast to get setup and I don't even have to do any work! Great service to make your cookgroup even better.",
      name: "vincentlamaaa ",
      contact: "Shock Notify",
      link: "https://twitter.com/Shock_Notify",
    },

    {
      img: rogue,
      title: "Design Quality & performance",
      text: "Support on top of it. When I have questions they reply quickly. Set up went super smooth and Moover and other staff are on top everything.",
      name: "Zarco",
      contact: "Rogue",
      link: "https://torchlabs.xyz",
    },
    {
      img: infernolab,
      title: "Layout and organized layers",
      text: "Fast responses and great support and excellent service!.",
      name: "Inferno",
      contact: "Inferno Labs",
      link: "https://twitter.com/Inferno_labs",
    },
    {
      img: swiftcookies,
      title: "Modern look & trending design",
      text: "Brilliant service! First time setting something like this up and they took me through it in no time, great support. Would recommend to anyone!.",
      name: "Ninasnkrs",
      contact: "Swift Cooks",
    },

    {
      img: crownaco,
      title: "Design Quality & performance",
      text: "Quick and easy set up. Appreciate them supporting a small business like mine and amazing response time and very professional.",
      name: "Tim𝟢𝓉𝒽𝓎",
      contact: "Crown ACO",
    },
    {
      img: okami,
      title: "Layout and organized layers",
      text: "Blazing fast setup & was up and working very, very quickly. Amazing.   Play your cards right and you can get sales fast",
      name: "okami",
    },
    {
      img: nextproxy,
      title: "Modern look & trending design",
      text: "Setup was a breeze!  There is no better option to setup your own custom brand at NO cost.  Highly recommend!",
      name: "bigbenbrod",
      contact: "Nest Proxy",
    },

    {
      img: paradise,
      title: "Design Quality & performance",
      text: "Setup was a breeze!  There is no better option to setup your own custom brand at NO cost.  Highly recommend!.",
      name: "xae",
      contact: "Palms Paradise",
    },
    {
      img: shockynotify,
      title: "Modern look & trending design",
      text: "Amazing service, super easy and fast to get setup and I don't even have to do any work! Great service to make your cookgroup even better.",
      name: "vincentlamaaa ",
      contact: "Shock Notify",
      link: "https://twitter.com/Shock_Notify",
    },

    {
      img: rogue,
      title: "Design Quality & performance",
      text: "Support on top of it. When I have questions they reply quickly. Set up went super smooth and Moover and other staff are on top everything.",
      name: "Zarco",
      contact: "Rogue",
      link: "https://torchlabs.xyz",
    },
    {
      img: infernolab,
      title: "Layout and organized layers",
      text: "Fast responses and great support and excellent service!.",
      name: "Inferno",
      contact: "Inferno Labs",
      link: "https://twitter.com/Inferno_labs",
    },
    {
      img: swiftcookies,
      title: "Modern look & trending design",
      text: "Brilliant service! First time setting something like this up and they took me through it in no time, great support. Would recommend to anyone!.",
      name: "Ninasnkrs",
      contact: "Swift Cooks",
    },

    {
      img: crownaco,
      title: "Design Quality & performance",
      text: "Quick and easy set up. Appreciate them supporting a small business like mine and amazing response time and very professional.",
      name: "Tim𝟢𝓉𝒽𝓎",
      contact: "Crown ACO",
    },
    {
      img: okami,
      title: "Layout and organized layers",
      text: "Blazing fast setup & was up and working very, very quickly. Amazing.   Play your cards right and you can get sales fast",
      name: "okami",
    },
    {
      img: nextproxy,
      title: "Modern look & trending design",
      text: "Setup was a breeze!  There is no better option to setup your own custom brand at NO cost.  Highly recommend!",
      name: "bigbenbrod",
      contact: "Nest Proxy",
    },

    {
      img: paradise,
      title: "Design Quality & performance",
      text: "AMAZING,so glad to have these in my group and brings such a nice feature easy setup and all, love this",
      name: "xae",
      contact: "Palms Paradise",
    },
    {
      img: shockynotify,
      title: "Modern look & trending design",
      text: "Amazing service, super easy and fast to get setup and I don't even have to do any work! Great service to make your cookgroup even better.",
      name: "vincentlamaaa ",
      contact: "Shock Notify",
      link: "https://twitter.com/Shock_Notify",
    },

    {
      img: rogue,
      title: "Design Quality & performance",
      text: "Support on top of it. When I have questions they reply quickly. Set up went super smooth and Moover and other staff are on top everything.",
      name: "Zarco",
      contact: "Rogue",
      link: "https://torchlabs.xyz",
    },
    {
      img: infernolab,
      title: "Layout and organized layers",
      text: "Fast responses and great support and excellent service!.",
      name: "Inferno",
      contact: "Inferno Labs",
      link: "https://twitter.com/Inferno_labs",
    },
    {
      img: swiftcookies,
      title: "Modern look & trending design",
      text: "Brilliant service! First time setting something like this up and they took me through it in no time, great support. Would recommend to anyone!.",
      name: "Ninasnkrs",
      contact: "Swift Cooks",
    },

    {
      img: crownaco,
      title: "Design Quality & performance",
      text: "Quick and easy set up. Appreciate them supporting a small business like mine and amazing response time and very professional.",
      name: "Tim𝟢𝓉𝒽𝓎",
      contact: "Crown ACO",
    },
    {
      img: okami,
      title: "Layout and organized layers",
      text: "Blazing fast setup & was up and working very, very quickly. Amazing.   Play your cards right and you can get sales fast",
      name: "okami",
    },
    {
      img: nextproxy,
      title: "Modern look & trending design",
      text: "Setup was a breeze!  There is no better option to setup your own custom brand at NO cost.  Highly recommend!",
      name: "bigbenbrod",
      contact: "Nest Proxy",
    },

    {
      img: paradise,
      title: "Design Quality & performance",
      text: "Setup was a breeze!  There is no better option to setup your own custom brand at NO cost.  Highly recommend!.",
      name: "xae",
      contact: "Palms Paradise",
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 20,
    autoplay: true,
    speed: 200000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 20,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 20,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 20,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 20,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 20,
        },
      },
    ],
  };
  return (
    <Wrapper>
      <span className="section-name ">Testimonial</span>
      <h2 className="t-heading">Don't just take our word for it.</h2>

      <Slider {...settings} className="">
        {testimonialArray.map((el, i) => (
          <div className="d-flex ">
            <Col className="testimonial mx-auto  p-2">
              <div className=" testimonial2">
                {" "}
                <p className="title">{el.title}</p>
                <p className="text">{el.text}</p>
                <div className="info">
                  <img src={el.img} alt="" className="profile" />
                  <div className="nameAndcontact">
                    <span className="name">{el.name}</span>
                    <a href={el.link} target="_blank" rel="noreferrer">
                      <span className="contact">{el.contact}</span>
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        ))}
      </Slider>
    </Wrapper>
  );
};
export default Testimonnial;
// {testimonialArray.map((el, i) => (
//     <Col className="testimonial mx-auto  p-2">
//       <div className=" testimonial2">
//         {" "}
//         <p className="title">{el.title}</p>
//         <p className="text">{el.text}</p>
//         <div className="info">
//           <img src={el.img} alt="" className="profile" />
//           <div className="nameAndcontact">
//             <span className="name">{el.name}</span>
//             <span className="contact">{el.contact}</span>
//           </div>
//         </div>
//       </div>
//     </Col>
//   ))}
// import React, { Component } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const Testimonial = () => {
//   const settings = {
//     dots: true,
//     infinite: true,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     speed: 2000,
//     autoplaySpeed: 2000,
//     cssEase: "linear",
//   };

//   return (
//     <div>
//       <h2>Auto Play</h2>
//       <Slider {...settings}>
//         <div>
//           <h3>1</h3>
//         </div>
//         <div>
//           <h3>2</h3>
//         </div>
//         <div>
//           <h3>3</h3>
//         </div>
//         <div>
//           <h3>4</h3>
//         </div>
//         <div>
//           <h3>5</h3>
//         </div>
//         <div>
//           <h3>6</h3>
//         </div>
//       </Slider>
//     </div>
//   );
// };
// export default Testimonial;

import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import privates from "../../images/private.png";
import getyours from "../../images/getyours.png";
import allthebest from "../../images/allthebest.png";
import realtime from "../../images/realtime.png";
import fast from "../../images/fast.png";
import fullycustom from "../../images/fullycustom.png";
import fullycustom2 from "../../images/fullycustom2.png";

import { useMainContext } from "../Context/Context";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #000;
  color: #fff;
  padding: 40px 30px;
  .core-container2 {
    padding: 40px 0;
  }
  .core-heading {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 52px;
    margin: 0;
  }
  .core-title {
    ont-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 48px;
    color: #ffffff;
  }
  .core-subtitle {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    width: 85%;
    margin: 0;
  }
  .features-image {
    width: 90%;
    border-radius: 12px;
  }
  @media only screen and (max-width: 991px) {
    .core-title {
      ont-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 48px;
      color: #ffffff;
    }
    .core-subtitle {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 26px;
      color: #ffffff;
      width: 85%;
    }
  }
  @media only screen and (max-width: 767px) {
    .core-subtitle {
      width: 100%;
    }

    .core-container {
      flex-direction: column-reverse;
    }

    .core-container2 {
      padding: 40px 0;
      flex-direction: column-reverse;
    }
    .core-container-text {
      text-align: center;
    }
  }
  @media only screen and (max-width: 520px) {
    .core-heading {
      font-size: 36px;
    }
    .core-title {
      ont-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 48px;
      color: #ffffff;
    }
    .core-subtitle {
      font-size: 12px;
    }

    .core-container {
      flex-direction: column-reverse;
    }

    .core-container2 {
      padding: 40px 0;
      flex-direction: column-reverse;
    }
    .features-image {
      width: 100%;
    }
  }
`;
const CoreFeatures = () => {
  const { proxy } = useMainContext();
  const proxybot = [
    {
      image: privates,
      title: "Private Residential + ISPs",
      text: "We offer 3 private residential pools and our own in house ISPs hosted on Sprint/Comcast/ATT/CenturyLink ISP networks. Best part is you never pay for anything! ",
      no: true,
    },
    {
      image: realtime,
      title: "Real Time Admin Dashboard",
      text: "Our intuitive dashboard allows server owners and admins to view detailed statistics in real time. You can also make custom discount codes instantly and giveaway data to your members for free! All through our dashboard.",
    },
    {
      image: fast,
      title: "Fast & Secure Checkout ",
      text: "With our integrated stripe checkout, your users will get a fast and secure checkout experience so they can start using their proxies instantly. ",
    },
    {
      image: fullycustom,
      title: "Fully Custom Experience",
      text: "We provide fully custom discord proxy bots to your server for free! By adding your brand name and logo, we ensure your users get a custom experience. ",
    },
  ];
  const proxydashboard = [
    {
      image: getyours,
      title: "Get Your Dashboard Instantly",
      text: "Our Dashboards are ready to deploy in seconds! Unlike all the other dashboards in the market, we offer instant deployment! You can start your own proxy company within minutes! ",
      no: true,
    },
    {
      image: allthebest,
      title: "All The Best Providers Built In",
      text: "We offer 3 private residential pools and our own in house ISPs hosted on Sprint/Comcast/ATT/CenturyLink ISP networks. Best part is you never pay for anything! ",
      no: true,
    },
    {
      image: realtime,
      title: "Real Time Admin Dashboard",
      text: "Our intuitive dashboard allows server owners and admins to view detailed statistics in real time. You can also make custom discount codes instantly and giveaway data to your members for free! All through our dashboard.",
    },
    {
      image: fast,
      title: "Fast & Secure Checkout ",
      text: "With our integrated stripe checkout, your users will get a fast and secure checkout experience so they can start using their proxies instantly. ",
    },
    {
      image: fullycustom2,
      title: "Fully Custom Experience",
      text: "We provide fully custom discord proxy bots to your server for free! By adding your brand name and logo, we ensure your users get a custom experience. ",
    },
  ];
  return (
    <Wrapper>
      <h2 className="core-heading py-4">Core Features</h2>
      {proxy &&
        proxydashboard.map((el, i) => (
          <Row
            className="d-flex justify-content-center align-items-center core-container2 "
            key={i}
          >
            <Col
              md={6}
              data-aos="fade-up"
              className="d-flex justify-content-center align-items-center flex-column"
            >
              <img src={el.image} alt="" className="features-image" />
            </Col>
            <Col
              md={6}
              className="py-4 py-md-0  core-container-text"
              data-aos="fade-left"
            >
              {" "}
              <div style={{ display: "inline-block" }}>
                {" "}
                <h3 className="core-title" style={{ display: "inline-block" }}>
                  {el.title}
                </h3>
                <p
                  className="core-subtitle  "
                  style={{ display: "inline-block" }}
                >
                  {el.text}
                </p>
              </div>
            </Col>
          </Row>
        ))}
      {!proxy &&
        proxybot.map((el, i) => (
          <Row
            className={
              !el.no
                ? "d-flex justify-content-center align-items-center core-container2 "
                : "d-flex justify-content-center align-items-center core-container core-container"
            }
            key={i}
          >
            <Col
              md={6}
              data-aos="fade-up"
              className="d-flex justify-content-center align-items-center flex-column"
            >
              <img
                src={el.image}
                alt=""
                className="features-image"
                style={{ width: el.no && "100%" }}
              />
            </Col>
            <Col
              md={6}
              className="py-4 py-md-0  core-container-text"
              data-aos="fade-left"
            >
              {" "}
              <div style={{ display: "inline-block" }}>
                {" "}
                <h3 className="core-title" style={{ display: "inline-block" }}>
                  {el.title}
                </h3>
                <p
                  className="core-subtitle  "
                  style={{ display: "inline-block" }}
                >
                  {el.text}
                </p>
              </div>
            </Col>
          </Row>
        ))}
    </Wrapper>
  );
};
export default CoreFeatures;

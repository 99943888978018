import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import Logo from "../Logo/Logo";
import { BsDiscord, BsTwitter } from "react-icons/bs";

const Wrapper = styled.div`
  overflow-x: hidden;
  background: #000;
  padding: 0 40px 0 20px;
  .logo-copyright {
    display: flex;
    align-items: center;
  }
  .copyright {
    font-family: "Rubik";
    font-style: normal;
    font-weight: normal;
    font-size: 14.8778px;
    line-height: 26px;
    /* or 175% */

    letter-spacing: 0.01em;

    /* Neutral/White */

    color: #ffffff;
  }
  .social-container {
    display: flex;
    justify-content: flex-end;
    font-size: 25px;
  }
  .icon {
    font-size: 30px;
    color: #56ccf2;
  }
  @media only screen and (max-width: 767px) {
    .logo-copyright {
      justify-content: center;
    }
    .social-container {
      justify-content: center;

      padding-bottom: 50px;
    }
  }
  @media only screen and (max-width: 520px) {
    .copyright {
      font-size: 12px;
    }
    padding: 0 20px 0 8px;
  }
`;
const Footer = () => {
  return (
    <Wrapper>
      <Row className="d-flex justify-content-between align-items-center">
        <Col md={9} className="logo-copyright">
          <Logo />
          <span className="copyright">
            ©2021 Torch Labs Software. All rights reserved
          </span>
        </Col>
        <Col md={3} className="social-container">
          <a
            href="https://discord.gg/myfMsdQhER"
            target="_blank"
            rel="noreferrer"
          >
            <BsDiscord className="mx-4 icon" />
          </a>
          <a
            href="https://twitter.com/torch_labs"
            target="_blank"
            rel="noreferrer"
          >
            <BsTwitter className="icon" />
          </a>
        </Col>
      </Row>
    </Wrapper>
  );
};
export default Footer;

import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import Buttons from "../Buttons/Button";
import banner from "../../images/banner.png";
import Navbar from "../Navbar/Navbar";

const Wrapper = styled.div`
  background: #000;
  padding: 0 50px;
  padding-bottom: 50px;

  .discountAndProxy {
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    padding: 12px 8px;
    border-radius: 32px;
    width: 75%;
  }

  .discount {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #0d0d2b;
    padding: 8px 18px;
    font-weight: 500;
    border-radius: 32px;

    background: #fff;
  }
  .proxy {
    padding: 0 15px;
    font-weight: 400;
  }
  .hero-heading {
    font-family: "Rubik";
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 76px;
    width: 98%;
    color: #ffffff;

    padding: 30px 0px;
    margin: 0;
  }
  .hero-subtitle {
    margin: 0;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #e0e0e0;
    width: 67%;
  }
  .banner-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .banner-image {
    width: 85%;

    box-sizing: border-box;

    overflow: hidden;
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
    animation: float 6s ease-in-out infinite;
  }
  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
  }
  @media only screen and (min-width: 1366px) {
    .discountAndProxy {
      background: rgba(255, 255, 255, 0.1);
      color: #fff;
      padding: 12px 8px;
      border-radius: 32px;
      width: 450px;
    }
    .banner-image {
      width: 70%;
    }
  }
  @media only screen and (max-width: 1485px) {
    .discountAndProxyContainer {
      padding-top: 70px;
    }
  }
  @media only screen and (max-width: 1199px) {
    .discountAndProxy {
      width: 90%;
    }
    .hero-heading {
      width: 100%;
      font-size: 50px;
      line-height: 60px;
    }
    .hero-subtitle {
      width: 75%;
      font-size: 14px;
    }
    .banner-image {
      width: 90%;
    }
  }
  @media only screen and (max-width: 991px) {
    .discountAndProxy {
      width: 100%;
      font-size: 14px;
    }
    .hero-heading {
      width: 100%;
      font-size: 44px;
      line-height: 60px;
    }
    .hero-subtitle {
      width: 95%;
      font-size: 13px;
    }
    .banner-image {
      width: 80%;
    }
  }
  @media only screen and (max-width: 767px) {
    .discountAndProxy {
      width: 80%;
    }
  }
  @media only screen and (max-width: 576px) {
    .banner-image {
      width: 85%;
    }
    .discountAndProxy {
      width: 100%;
    }
    .hero-heading {
      width: 100%;
      font-size: 39px;
      line-height: 50px;
    }
    .hero-subtitle {
      width: 80%;
      font-size: 12px;
      line-height: 20px;
    }
  }
  @media only screen and (max-width: 450px) {
    padding: 0 20px;
    .banner-image {
      width: 100%;
    }
    .discount {
      font-size: 12px;
    }
    .discountAndProxy {
      font-size: 12px;
    }
  }
`;
const HeroSection = () => {
  return (
    <>
      <Navbar />
      <Wrapper id="about">
        <Row className=" align-items-center">
          <Col md={6} className="discountAndProxyContainer">
            <div className="discountAndProxy ">
              <span className="discount">25% SAVE</span>
              <span className="proxy">On Our Proxy Dashboard</span>
            </div>
            <h1 className="hero-heading">
              Welcome to your all in one proxy solution
            </h1>
            <p className="hero-subtitle">
              We offer fully custom discord proxy bots for your server all for
              Free! Provide your members with a high quality proxies while
              adding a new source of income!
            </p>
            <a
              href="https://discord.gg/myfMsdQhER"
              target="_blank"
              rel="noreferrer"
            >
              <Buttons>Join for FREE</Buttons>
            </a>
          </Col>
          <Col md={6} className="banner-image-container ">
            <img src={banner} alt="" className="banner-image" />
          </Col>
        </Row>
      </Wrapper>
    </>
  );
};
export default HeroSection;

import React from "react";
import styled from "styled-components";
import { Col } from "react-bootstrap";
import video from "../../images/video.mp4";
import image from "../../images/image.png";
import { useMainContext } from "../Context/Context";

const Wrapper = styled.div`
  display: flex;
  background-color: #000;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
  position: relative;

  .h-heading {
    margin: 0;
    padding-bottom: 40px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 52px;
    color: #fff;
  }
  .h-subtitle {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    padding: 0 15px;
    padding-top: 35px;
    text-align: center;
  }
  .image {
    width: 100%;
    border-radius: 12px;
  }
  video {
    border-radius: 12px;
  }
  .box {
    position: relative;

    display: block;
    background: linear-gradient(0deg, black, #444444);
    box-shadow: 0px 0px 20px 5px #ff7c4e;
    border-radius: 12px;
  }

  .glowing::before {
    content: "";
    position: absolute;
    left: -2px;
    top: -2px;
    background: linear-gradient(
      45deg,
      #e8f74d,
      #ff6600d9,
      #00ff66,
      #ad27ad,
      #bd2681,
      #6512b9,
      #ff3300de,
      #5aabde
    );
    background-size: 400%;
    width: calc(100% + 5px);
    height: calc(100% + 5px);
    z-index: -1;
    animation: glower 20s linear infinite;
    border-radius: 12px;
  }

  @keyframes glower {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }
`;

const HowItWorks = () => {
  const { proxy } = useMainContext();
  return (
    <Wrapper>
      <h2 className="h-heading">How It Works</h2>
      <Col xs={11} sm={10} lg={8} className="mx-auto box glowing">
        {!proxy ? (
          <video autoplay="autoplay" loop width="100%" muted>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img src={image} alt="" className="image" />
        )}
      </Col>
      <p className="h-subtitle">
        {!proxy
          ? "Lightning fast proxy bot in your group. 100% free and 5 minute set up!"
          : "Extremly modern UI packed with world class performance to suit all your proxy needs."}
      </p>
    </Wrapper>
  );
};
export default HowItWorks;

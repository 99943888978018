import React from "react";
import styled from "styled-components";

import HeroSection from "../Components/HeroSection/HeroSection";
import CountUps from "../Components/CountUp/CountUp";
import HowItWorks from "../Components/HowItWorks/HowItWorks";
import SwitchButton from "../Components/ButtonSwitch/ButtonSwitch";
import CoreFeatures from "../Components/CoreFeatures/CoreFeatures";
import Testimonnial from "../Components/Testimonial/Testimonial";
import AnyQuestion from "../Components/AnyQuestion/AnyQuestion";
import Footer from "../Components/Footer/Footer";

const Wrapper = styled.div`
  overflow-x: hidden;
`;
const Home = () => {
  return (
    <Wrapper>
      <HeroSection />
      <CountUps />

      <SwitchButton />
      <HowItWorks />
      <CoreFeatures />
      <Testimonnial />
      <AnyQuestion />
      <Footer />
    </Wrapper>
  );
};
export default Home;

import React from "react";
import CountUp from "react-countup";
import { Row, Col } from "react-bootstrap";
import VisibilitySensor from "react-visibility-sensor";
import styled from "styled-components";
import chart from "../../images/Bar Chart.png";
import person from "../../images/Person.png";
import earth from "../../images/Earth.png";
const Wrapper = styled.div`
  overflow-x: hidden;
  background: #000;
  .counter-container {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .c-counter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-container {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #191919;
    text-align: center;
    line-height: 80px;
  }
  .icon {
    width: 32px;
    height: 32 px;
  }
  .content {
    color: #000;
  }
  .counter-text {
    font-family: "Rubik";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 150%;
    color: #fff;
    padding: 0 15px;
  }
  .count-text {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;

    letter-spacing: 0.01em;

    color: #e0e0e0;
  }
  @media only screen and (max-width: 1199px) {
    .icon-container {
      width: 50px;
      height: 50px;
      line-height: 50px;
    }
    .counter-text {
      font-size: 24px;
    }
    .count-text {
      font-size: 14px;
    }
    .icon {
      width: 20px;
      height: 20 px;
    }
  }
  @media only screen and (max-width: 991px) {
    .counter-container {
      height: 250px;
    }
  }

  @media only screen and (max-width: 575px) {
    .c-counter {
      justify-content: flex-start;
    }
    .counter-container {
      height: 330px;
    }
    .second-containers {
      width: 70% !important;
    }
  }
  @media only screen and (max-width: 520px) {
    .second-containers {
      width: 60% !important;
    }
  }
  @media only screen and (max-width: 450px) {
    .second-containers {
      width: 70% !important;
    }
  }
  @media only screen and (max-width: 380px) {
    .second-containers {
      width: 85% !important;
    }
  }
`;
export default function CountUps() {
  const counterArray = [
    {
      icon: chart,
      number: 100000,
      text: "Earned by Group Owners",
      dolar: "$",
      plus: "+",
    },
    {
      icon: person,
      number: 100,
      text: "Trusted by Many Companies",
      plus: "+",
    },
    { icon: earth, number: 195, text: "Countries Supported" },
  ];
  return (
    <Wrapper>
      <VisibilitySensor>
        {({ isVisible }) => (
          <div className="counter-container " style={{ paddingBottom: "50px" }}>
            {isVisible ? (
              <Col sm={12} md={11} className="mx-auto second-containers ">
                <Row className="">
                  {counterArray.map((el, i) => (
                    <Col sm={6} lg={4} key={i} className="c-counter   py-3">
                      <div className="icon-container">
                        <img src={el.icon} alt="#" className="icon" />
                      </div>
                      <div className="counter-text">
                        <div>
                          {el.dolar}
                          <CountUp
                            start={1}
                            end={el.number}
                            duration={1}
                            separator=","
                          />
                          {el.plus}
                        </div>
                        <p className="count-text">{el.text}</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            ) : (
              ""
            )}
          </div>
        )}
      </VisibilitySensor>
    </Wrapper>
  );
}

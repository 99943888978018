import React from "react";

import { Col } from "react-bootstrap";
import styled from "styled-components";
import { useMainContext } from "../Context/Context";
const Wrapper = styled.div`
  background: #000;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  overflow-x: hidden;
  .t-heading {
    font-family: "Rubik";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 47px;
    margin: 0;
    text-align: center;

    /* Neutral / White */

    color: #ffffff;
  }
  .button-box {
    margin: 35px auto;
    position: relative;
    border-radius: 30px;
    background: #000;
    display: flex;
    justify-content: space-between;
    border: 1.5px solid #da3a02;
  }

  .toggle-btn {
    padding: 10px 40px;
    cursor: pointer;
    background: transparent;
    border: 0;
    outline: none;
    position: relative;
    text-align: center;
    color: #fff;
    width: 50%;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;

    letter-spacing: 0.01em;

    color: #ffffff;
  }

  #btn {
    left: -5px;
    top: -1px;
    right: -1px;
    bottom: -1px;
    position: absolute;
    width: 50.5%;
    height: 105%;
    background: #da3a02;
    border-radius: 30px;
    transition: 0.5s;
  }
  @media only screen and (max-width: 600px) {
    .toggle-btn {
      padding: 10px 10px;
      font-size: 14px;
    }
    .t-heading {
      font-size: 26px;

      /* Neutral / White */

      color: #ffffff;
    }
  }
  @media only screen and (max-width: 450px) {
    .toggle-btn {
      padding: 10px 10px;
      font-size: 10px;
    }
  }
`;

const SwitchButton = () => {
  const { setProxy } = useMainContext();
  const leftClick = () => {
    document.querySelector("#btn").style.left = "-3px";
    document.querySelector(".leftselect").style.fontWeight = "700";
    document.querySelector(".rightselect").style.fontWeight = "400";

    setProxy((prev) => !prev);
  };

  const rightClick = () => {
    document.querySelector("#btn").style.left = "50%";
    document.querySelector(".rightselect").style.fontWeight = "700";
    document.querySelector(".leftselect").style.fontWeight = "400";

    setProxy((prev) => !prev);
  };
  return (
    <Wrapper id="explore">
      <h2 className="t-heading py-2">Explore Our Products</h2>
      <Col xs={11} md={8} class="form-box mx-auto">
        <div class="button-box">
          <div id="btn"></div>
          <button
            type="button"
            class="toggle-btn leftselect"
            onClick={() => {
              leftClick();
              setProxy(false);
            }}
          >
            Free Proxy Bot
          </button>
          <button
            type="button"
            class="toggle-btn rightselect"
            onClick={() => {
              rightClick();
              setProxy(true);
            }}
          >
            Premium Proxy Dashboard
          </button>
        </div>
      </Col>
    </Wrapper>
  );
};

export default SwitchButton;
